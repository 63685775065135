body {
  margin: 0;
  background-color: #201a16;
  display: block;
}

html {
    display: block;
    font-family: "futura-pt", "europa", sans-serif;
    color: -internal-root-color;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-locale: "es";
}

*::selection {
    background-color: #000;
    color: #fff;
}
